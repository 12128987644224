import Cookie from "js-cookie";
import fileDownload from "js-file-download";

export class Request {

    constructor() {
        let env = process.env as any
        this.url = env['REACT_APP_API']
    }

    private url: string
    private renewToken(): PromiseLike<any> {
        return new Promise((res, rej) => {
            fetch(this.url + 'login/renovar-token', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'url': window.location.href,
                    'jwt': Cookie.get('token') == undefined ? "" : Cookie.get('token')!
                }
            }).then(res => res.json()).then((response) => {
                Cookie.set('token', response.token)
                return res(true)
            }, e => {
                return rej(e)
            })
        })
    }

    public requestFile(rota: any, formData: any): PromiseLike<any> {

        return new Promise((res, rej) => {
            fetch(this.url + rota, {
                method: 'POST',
                headers: {
                    'url': window.location.href,
                    'jwt': Cookie.get('token') == undefined ? "" : Cookie.get('token')!
                },
                body: formData
            }).then(res => res.json())
                .then((response) => {
                    if (response.Codigo && response.Codigo == "401") {
                        alert('Sessão inválida ou expirou, logue novamente')
                        let database = localStorage.getItem('database')
                        window.location.href = `/${database}/login`
                        return
                    }

                    if (response.Status && response.Status == "Erro" && window.location.href.indexOf('login') == -1) {
                        return rej(response);
                    }

                    if (rota == 'login/autenticar') {
                        return res(response)
                    }

                    this.renewToken().then(() => {
                        return res(response);
                    }, e => {
                        return rej(e)
                    })

                }, e => {
                    return rej(e)
                });
        });
    }

    public requestPost(rota: any, json: any): PromiseLike<any> {

        return new Promise((res, rej) => {
            fetch(this.url + rota, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'url': window.location.href,
                    'jwt': Cookie.get('token') == undefined ? "" : Cookie.get('token')!
                },
                body: JSON.stringify(json)
            }).then(res => res.json()).then((response) => {
                if (response.Codigo && response.Codigo == "401") {
                    alert('Sessão inválida ou expirou, logue novamente')
                    let database = localStorage.getItem('database')
                    window.location.href = `/${database}/login`
                    return
                }

                if (response.Status && response.Status == "Erro" && window.location.href.indexOf('login') == -1) {
                    return rej(response);
                }

                if (rota == 'login/autenticar') {
                    return res(response)
                }

                this.renewToken().then(() => {
                    return res(response);
                }, e => {
                    return rej(e)
                })
            }, e => {
                return rej(e);
            });
        });
    }

    public requestPdf(rota: any, json: any, pdfName: string): PromiseLike<any> {

        return new Promise((res, rej) => {
            fetch(this.url + rota, {
                method: "POST",
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/json',
                    'url': window.location.href,
                    'jwt': Cookie.get('token') == undefined ? "" : Cookie.get('token')!
                },
                body: JSON.stringify(json)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob(); // Converte a resposta para Blob
                })
                .then((blob) => {
                    // Verifica se a resposta é um PDF
                    if (blob.type === 'application/pdf') {
                        fileDownload(blob, `Listagem-Propriedades-${pdfName}.pdf`)
                    } else {
                        throw new Error('A resposta não é um PDF');
                    }

                    res(true)
                })
                .catch((error) => {
                    console.error('Erro na requisição:', error);
                    return rej(error);
                });
        });
    }

    public requestPostDownload(rota: string, json: any, responseType: 'blob' | 'json' = 'json'): Promise<any> {
        return new Promise((resolve, reject) => {
            fetch(this.url + rota, {
                method: "POST",
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'url': window.location.href,
                    'jwt': Cookie.get('token') == undefined ? "" : Cookie.get('token')!
                },
                body: JSON.stringify(json)
            }).then(async (res) => {
                if (res.status === 401) {
                    alert('Sessão inválida ou expirou, logue novamente');
                    let database = localStorage.getItem('database');
                    window.location.href = `/${database}/login`;
                    return;
                }
                if(res.status === 400){
                    alert('Tipo de relatório inválido');
                    return;
                }
                if (responseType === 'blob') {
                    const fileName = res.headers.get('X-File-Name') || `arquivo.${json.formato}`;
                    const blob = await res.blob();
                    resolve({ data: blob, fileName });
                } else {
                    const json = await res.json();
                    if (json.Status && json.Status == "Erro" && window.location.href.indexOf('login') == -1) {
                        reject(json);
                    } else {
                        resolve(json);
                    }
                }
            }).catch(reject);
        });
    }


}