import React from 'react';
import ProtectedRoute from '../../Components/ProtectedRoute';
import { ExportDataRelatory, ExportDataRelatoryViewController } from '../../Screen/ExportDataRelatory/ExportDataRelatoryViewController';
import { ExportDataRelatoryViewModel } from '../../Screen/ExportDataRelatory/ExportDataRelatoryViewModel';
import { IndexCoordinatorDelegate } from "../IndexCoordinator";

export class ExportDataRelatoryCoordinator implements IndexCoordinatorDelegate, ExportDataRelatory {

    getRoute(): JSX.Element[] {
        return [
            this.routeExportDataRelatory(),
        ]
    }

    private routeExportDataRelatory(): JSX.Element {
        var viewModel = new ExportDataRelatoryViewModel()
        return (<ProtectedRoute requiredPermissions={['relatorios.exportar_dados.*']} path="/:base/relatorio/exportar-dados" exact={true} component={(props: any) => {
            return (<ExportDataRelatoryViewController
                navigation={props} 
                viewModel={viewModel} 
                delegate={this} 
            />)
        }}/>)
    }

    tapDimiss(navigation: any): void {
        navigation.history.goBack();
    }
}