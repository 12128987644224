
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-editable';
import 'leaflet/dist/leaflet.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import filter from '../../Imagens/filter-black.png';
import { MapFilterViewModel } from '../../Screen/MapFilters/MapFiltersViewModel';
import { FilterCheckboxLabelsComponent } from './FiltrosCamposComponente';
import { LoaderComponente } from '../LoaderComponente';
import SelectComponent from '../SelectComponent';
import MultiSelectField from '../SelectMultiComponent';
import moment from 'moment';
import { GlobalViewModel } from '../../Screen/Global/GlobalViewModel';
import { FiltroCategoriaComponent } from './FiltroCategoria';
import { FiltroButtonsComponent } from './FiltrosButtons';

interface MyProps {
    getMap: () => any
    openFilter: (isOpen: Boolean) => void,
    openCamada: (isOpen: Boolean) => void,
    isOpenFilter: Boolean
    selectedLayers: Array<any>
    setGeoJson: (scamada: Array<any>, geojson: Array<any>, tiles: Array<any>, isFilter: boolean) => void,
    clearAllLayers: () => void,
    storagedFilters: any
}

export const FilterInMapComponent = ({ getMap, openCamada, isOpenFilter, openFilter, setGeoJson, selectedLayers, clearAllLayers, storagedFilters }: MyProps) => {

    const viewModel = new MapFilterViewModel();
    const globalViewModel = new GlobalViewModel()
    const origemList = [
        { value: 'C', label: 'Cliente' },
        { value: 'G', label: 'GeoEfficace' }
    ]
    const tipoPropriedadeList = [
        { value: 'U', label: 'Urbano' },
        { value: 'R', label: 'Rural' },
        { value: 'C', label: 'Cemitério' },
        { value: 'D', label: 'Pontos Diversos' },
    ]
    const [layersList, setLayersList] = useState<Array<any>>(selectedLayers ? selectedLayers : [])
    const [filterLayers, setFilterLayers] = useState<Array<number>>([])
    const [showLoading, setShowLoading] = useState(false);
    const [openedFilter, setOpenedFilter] = useState('');
    const [situationList, setSituationList] = useState([]);
    const [tipoPontoList, setTipoPontoList] = useState([]);
    const [tipoPonto, setTipoPonto] = useState("");
    const [filled, setFilled] = useState(false);
    const [filters, setFilters] = useState(
        {
            selectedcgepsituacoesshape: '',
            selectedsgeptipoponto: '',
            origem: '',
            haveImagem: false,
            cgepsituacoesshape: false,
            sgeptipoponto: false,
            logradouro: false,
            numero: false,
            sgepbairros: false,
            cep: false,
            sgepdistrito: false,
            referencia: false,
            descricao: false,
            ladologradouro: false,
            complemento: false,
            ccidade: false,
            tipo: false,
            sgepzoneamento: false,
            zona: false,
            principal: false,
            anoinicial: false,
            anofinal: false,
            inscricaomunicipal: false,
            sgepformaobtencao: false,
            numeromatricula: false,
            cnscartorio: false,
            numeroquadra: false,
            numerolote: false,
            anolotamento: false,
            sgepproptpacesso: false,
            sgeppiscina: false,
            datamatricula: false,
            sgeptipopatrimonio: false,
            gepproplitigio: false,
            area: false,
            possuiHistorico: false,
            numerogleba: false,
            seccao: false,
            sgeplocalizacaorural: false,
            numeroHistoricos: '',
            possuiDescricao: false,
            possuiEdificacao: false,
            possuiArquivosDigitais: false,
            numeroArquivosDigitais: '',
            numeroEdificacao: '',
            datainicial: false,
            datafinal: false,
            observacao: false,
            sgeptipoedificacao: false,
            numeroVinculados: '',
            propriedade: false,
            edificacao: false,
            possuiVinculados: false,
        });


    function saveFilters() {
        let expireDate = new Date();
        expireDate.setMinutes(expireDate.getMinutes() + 20);
        const filtersStorage = {
            tipoPonto,
            filterLayers,
            filters,
            filled,
            openedFilter,
            expireDate: moment(expireDate).format('DD/MM/YYYY HH:mm:ss')
        }
        localStorage.setItem('filters', JSON.stringify(filtersStorage))
    }

    useEffect(() => {
        const layerStoraged = JSON.parse(String(localStorage.getItem('layerOptions')))
        if (layerStoraged) {
            setLayersList(layerStoraged)
        }
        if (isOpenFilter && Object.keys(storagedFilters).length === 0) {

        }
    }, [isOpenFilter])

    useEffect(() => {
        document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', 'margin-top: 20px;');

        globalViewModel.fetchDataToSelectInput('gepsituacoesshape', 'cgepsituacoesshape').then((response: any) => {
            if (response.length) {
                setSituationList(response)
            }
        })
        globalViewModel.fetchDataToSelectInput('geptipoponto', 'sgeptipoponto').then((response: any) => {
            if (response.length) {
                setTipoPontoList(response)
            }
        })

        if (storagedFilters && Object.keys(storagedFilters).length !== 0) {
            setFilterLayers(storagedFilters.filterLayers)
            setFilters(storagedFilters.filters)
            setTipoPonto(storagedFilters.tipoPonto)
            setOpenedFilter(storagedFilters.openedFilter)
            setFilled(storagedFilters.filled)
            getPoints(storagedFilters)

            var style = document.querySelector('.leaflet-left .leaflet-control')?.getAttribute('style')
            document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', `${style}margin-left: 510px;`)
            document.querySelector('.procurar')?.setAttribute('style', `margin-top: 0px; margin-left: 500px;`)
            document.querySelector('.container-procurar')?.setAttribute('style', `margin-left: 500px;`)
            document.querySelector('#camadas')?.setAttribute('style', `margin-left: 500px;`)
            document.querySelector('.filtros')?.setAttribute('style', `margin-left: 500px;`)
            document.querySelector('.leaflet-control-measure')?.setAttribute('style', `margin-left: 500px;`)
        } else {
            clearAll()
            localStorage.removeItem('filters')
            localStorage.removeItem('layerOptions')
            localStorage.removeItem('selectedLayer')
        }
    }, [])

    function getPoints(storaged?: any) {

        var openedFilterLocal = storaged ? storaged.openedFilter : openedFilter
        var filledLocal = storaged ? storaged.filled : filled
        var tipoPontoLocal = storaged ? storaged.tipoPonto : tipoPonto
        var filtersLocal = storaged ? storaged.filters : filters
        var filterLayersLocal = storaged ? storaged.filterLayers : filterLayers

        var filteredFilters
        if (openedFilterLocal === 'fotoCapa') {
            filteredFilters = {
                haveImagem: filtersLocal.haveImagem
            }
        } else {
            filteredFilters = filterSelectedCheckboxes([filtersLocal])[0]
            if (!filledLocal) {
                for (const key in filteredFilters) {
                    if (key !== 'numeroHistoricos' &&
                        key !== 'selectedcgepsituacoesshape' &&
                        key !== 'selectedsgeptipoponto' &&
                        key !== 'origem' &&
                        key !== 'camadas' &&
                        key !== 'numeroArquivosDigitais' &&
                        key !== 'numeroEdificacao' &&
                        key !== 'numeroVinculados') {
                        filteredFilters[key] = false;
                    }
                }
            }
        }
        if (openedFilterLocal) {
            setShowLoading(true)
            viewModel.getMapPoints(tipoPontoLocal, filterLayersLocal, openedFilterLocal, filteredFilters).then((mapPoints: any) => {
                setGeoJson([0], [mapPoints], [], true)
                setShowLoading(false)
            })
        }
    }
    const filterSelectedCheckboxes = (filtros: Array<any>) => {
        return filtros.map((filtro) => {
            const propriedadesComValores: Record<string, any> = {};
            Object.entries(filtro).forEach(([chave, valor]) => {
                if (valor !== false && valor !== '') {
                    propriedadesComValores[chave] = valor;
                }
            });
            return propriedadesComValores;
        });
    }
    const clearMapFilters = () => {
        setFilled(false)
        setFilters({
            selectedcgepsituacoesshape: '',
            selectedsgeptipoponto: '',
            origem: '',
            haveImagem: false,
            cgepsituacoesshape: false,
            sgeptipoponto: false,
            logradouro: false,
            numero: false,
            sgepbairros: false,
            cep: false,
            sgepdistrito: false,
            referencia: false,
            descricao: false,
            ladologradouro: false,
            complemento: false,
            ccidade: false,
            tipo: false,
            sgepzoneamento: false,
            zona: false,
            principal: false,
            anoinicial: false,
            anofinal: false,
            inscricaomunicipal: false,
            sgepformaobtencao: false,
            numeromatricula: false,
            cnscartorio: false,
            numeroquadra: false,
            numerolote: false,
            anolotamento: false,
            sgepproptpacesso: false,
            sgeppiscina: false,
            datamatricula: false,
            sgeptipopatrimonio: false,
            gepproplitigio: false,
            area: false,
            possuiHistorico: false,
            numerogleba: false,
            seccao: false,
            sgeplocalizacaorural: false,
            numeroHistoricos: '',
            numeroEdificacao: '',
            possuiArquivosDigitais: false,
            possuiDescricao: false,
            possuiEdificacao: false,
            numeroArquivosDigitais: '',
            datainicial: false,
            datafinal: false,
            observacao: false,
            sgeptipoedificacao: false,
            numeroVinculados: '',
            propriedade: false,
            edificacao: false,
            possuiVinculados: false,
        })
    };
    const clearAll = () => {
        clearMapFilters()
        setFilterLayers([])
        setOpenedFilter("")
        setTipoPonto("")
    }
    const clickOpenFilters = () => {

        var style = document.querySelector('.leaflet-left .leaflet-control')?.getAttribute('style');
        if (isOpenFilter) {
            document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', 'margin-left: 10px; margin-top: 20px;');
            document.querySelector('.procurar')?.setAttribute('style', `margin-top: 0px; margin-left: 0px;`)
            document.querySelector('.container-procurar')?.setAttribute('style', `margin-top: 0px; margin-left: 0px;`)
            document.querySelector('#camadas')?.setAttribute('style', `left: 10px;`)
            document.querySelector('.filtros')?.setAttribute('style', `left: -5px;`)
            document.querySelector('.neighbor-relatory')?.setAttribute('style', `left: 10px`)
            document.querySelector('.leaflet-control-measure')?.removeAttribute('style')
            document.querySelector('.coordinates')?.removeAttribute('style')
            document.querySelector('.leaflet-control-graphicscale-inner')?.removeAttribute('style')
            openFilter(false)
            return;
        } else {
            document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', `${style}margin-left: 510px;`)
            document.querySelector('.procurar')?.setAttribute('style', `margin-top: 0px; margin-left: 500px;`)
            document.querySelector('.container-procurar')?.setAttribute('style', `margin-left: 500px;`)
            document.querySelector('#camadas')?.setAttribute('style', `margin-left: 500px;`)
            document.querySelector('.filtros')?.setAttribute('style', `margin-left: 500px;`)
            document.querySelector('.neighbor-relatory')?.setAttribute('style', `margin-left: 500px;`)
            document.querySelector('.leaflet-control-measure')?.setAttribute('style', `margin-left: 510px;`)
            document.querySelector('.coordinates')?.setAttribute('style', 'margin-left: 490px;')
            document.querySelector('.leaflet-control-graphicscale-inner')?.setAttribute('style', 'margin-left: 510px;')
            openFilter(true)
            openCamada(false)
            clearAll()
            localStorage.removeItem('filters')
        }
    }
    const selectedPointType = (tipoPonto: string) => {
        if (tipoPonto == "U") {
            return (
                <FilterCheckboxLabelsComponent
                    filters={filters}
                    filledButton
                    filled={filled}
                    setFilled={(filled: any) => setFilled(filled)}
                    labelArray={{
                        valueName: ['anoinicial', 'anofinal', 'inscricaomunicipal', 'sgepformaobtencao', 'numeromatricula', 'sgepdistrito', 'cnscartorio', 'numeroquadra', 'numerolote', 'anolotamento', 'sgepproptpacesso', 'sgeppiscina', 'datamatricula', 'sgepzoneamento', 'ccidade', 'sgeptipopatrimonio', 'gepproplitigio', 'area', 'possuiArquivosDigitais', 'possuiHistorico'],
                        labelName: ['Ano Inicial', 'Ano Final', 'Inscrição Municipal', 'Forma Obtenção', 'Nº Matrícula', 'Distrito', 'Cns Cartório', 'Nº Quadra', 'Nº Lote', 'Ano Loteamento', 'Tipo Acesso', 'Piscina', 'Data Matrícula', 'Zoneamento', 'Cidade', 'Patrimônio', 'Lítigio', 'Área', 'Arquivos Digitais', 'Hístorico'],
                        value: [filters.anoinicial, filters.anofinal, filters.inscricaomunicipal, filters.sgepformaobtencao, filters.numeromatricula, filters.sgepdistrito, filters.cnscartorio, filters.numeroquadra, filters.numerolote, filters.anolotamento, filters.sgepproptpacesso, filters.sgeppiscina, filters.datamatricula, filters.sgepzoneamento, filters.ccidade, filters.sgeptipopatrimonio, filters.gepproplitigio, filters.area, filters.possuiArquivosDigitais, filters.possuiHistorico],
                    }}
                    setState={(filters: any) => setFilters(filters)}
                />
            )
        }
        else if (tipoPonto == "R") {
            return (
                <FilterCheckboxLabelsComponent
                    filters={filters}
                    filledButton
                    filled={filled}
                    setFilled={(filled: any) => setFilled(filled)}
                    labelArray={{
                        valueName: ['anoinicial', 'anofinal', 'inscricaomunicipal', 'sgepformaobtencao', 'numeromatricula', 'sgepdistrito', 'cnscartorio', 'numerogleba', 'sgepproptpacesso', 'sgeppiscina', 'datamatricula', 'sgepzoneamento', 'ccidade', 'seccao', 'sgeptipopatrimonio', 'gepproplitigio', 'area', 'sgeplocalizacaorural', 'possuiArquivosDigitais', 'possuiHistorico'],
                        labelName: ['Ano Inicial', 'Ano Final', 'Inscrição Municipal', 'Forma Obtenção', 'Nº Matrícula', 'Distrito', 'Cns Cartório', 'Nº Gleba', 'Tipo Acesso', 'Piscina', 'Data Matrícula', 'Zoneamento', 'Cidade', 'Seção', 'Patrimônio', 'Lítigio', 'Área', 'Localização Rural', 'Arquivos Digitais', 'Hístorico'],
                        value: [filters.anoinicial, filters.anofinal, filters.inscricaomunicipal, filters.sgepformaobtencao, filters.numeromatricula, filters.sgepdistrito, filters.cnscartorio, filters.numerogleba, filters.sgepproptpacesso, filters.sgeppiscina, filters.datamatricula, filters.sgepzoneamento, filters.ccidade, filters.seccao, filters.sgeptipopatrimonio, filters.gepproplitigio, filters.area, filters.sgeplocalizacaorural, filters.possuiArquivosDigitais, filters.possuiHistorico],
                    }}
                    setState={(filters: any) => setFilters(filters)}
                />
            )
        } else if (tipoPonto == "D") {
            return (
                <FilterCheckboxLabelsComponent
                    filters={filters}
                    filledButton
                    filled={filled}
                    setFilled={(filled: any) => setFilled(filled)}
                    labelArray={{
                        valueName: ['anoinicial', 'anofinal', 'inscricaomunicipal', 'sgeptipoponto', 'possuiArquivosDigitais', 'possuiHistorico'],
                        labelName: ['Ano Inicial', 'Ano Final', 'Inscrição Municipal', 'Tipo Ponto', 'Arquivos Digitais', 'Hístorico'],
                        value: [filters.anoinicial, filters.anofinal, filters.inscricaomunicipal, filters.sgeptipoponto, filters.possuiArquivosDigitais, filters.possuiHistorico]
                    }}
                    setState={(filters: any) => setFilters(filters)} />
            )
        } else if (tipoPonto == "C") {
            return (
                <FilterCheckboxLabelsComponent
                    filters={filters}
                    filledButton
                    filled={filled}
                    setFilled={(filled: any) => setFilled(filled)}
                    labelArray={{
                        valueName: ['anoinicial', 'anofinal', 'inscricaomunicipal', 'numeroquadra', 'numerolote', 'possuiArquivosDigitais', 'possuiHistorico'],
                        labelName: ['Ano Inicial', 'Ano Final', 'Inscrição Municipal', 'Nº Quadra', 'Nº Lote', 'Arquivos Digitais', 'Hístorico'],
                        value: [filters.anoinicial, filters.anofinal, filters.inscricaomunicipal, filters.numeroquadra, filters.numerolote, filters.possuiArquivosDigitais, filters.possuiHistorico]
                    }}
                    setState={(filters: any) => setFilters(filters)} />
            )
        }
    }
    const disableMapFunctions = () => {
        const map = getMap();
        if (map) {
            map.dragging.disable(); // rolagem
            map.doubleClickZoom.disable(); // zoom no click duplo
            map.scrollWheelZoom.disable(); // zoom na rolagem
        }
    }
    const enableMapFunctions = () => {
        const map = getMap();
        if (map) {
            map.dragging.enable(); // rolagem
            map.doubleClickZoom.enable(); // zoom no click duplo
            map.scrollWheelZoom.enable(); // zoom na rolagem
        }
    }
    const gerarModalFilter = () => {
        return (
            <div className='filtro-container smallScrollbar' onMouseEnter={() => disableMapFunctions()} onMouseLeave={() => enableMapFunctions()}>
                <div className='filtro-content smallScrollbal'>
                    <Row className='filtro-categoria' >
                        <Col lg="12" xs="12">
                            <SelectComponent
                                label='Tipo de Ponto'
                                options={tipoPropriedadeList}
                                onChange={(e) =>
                                    setTipoPonto(e.target.value)
                                }
                                value={tipoPonto}
                            />
                        </Col>
                    </Row>

                    {tipoPonto &&
                        <>
                            {!!layersList.length &&
                                <Row className='filtro-categoria pt-0'>
                                    <Col lg={12}>
                                        <MultiSelectField
                                            label={"Camadas"}
                                            options={layersList}
                                            selectedItens={(layersList: Array<number>) => {
                                                setFilterLayers(layersList)
                                            }}
                                        />
                                    </Col>
                                </Row>}

                            <FiltroCategoriaComponent
                                labelName='Por foto de Capa'
                                openedFilterName='fotoCapa'
                                clearMapFilters={clearMapFilters}
                                setOpenedFilter={setOpenedFilter}
                                openedFilter={openedFilter}
                            />
                            {openedFilter === "fotoCapa" &&
                                <>
                                    <FilterCheckboxLabelsComponent
                                        filters={filters}
                                        labelArray={{
                                            valueName: ['haveImagem'],
                                            labelName: ['Foto de capa'],
                                            value: [filters.haveImagem]
                                        }}
                                        setState={setFilters}
                                    />
                                    <FiltroButtonsComponent
                                        clearAllLayers={clearAllLayers}
                                        clearMapFilters={clearMapFilters}
                                        getPoints={getPoints}
                                        saveFilters={saveFilters}
                                        setGeoJson={setGeoJson}
                                    />
                                </>
                            }


                            <FiltroCategoriaComponent
                                labelName='Dados de Editar'
                                openedFilterName='dadosEditar'
                                clearMapFilters={clearMapFilters}
                                setOpenedFilter={setOpenedFilter}
                                openedFilter={openedFilter}
                            />
                            {openedFilter === "dadosEditar" &&
                                <>
                                    <Row className='filtro-itens'>
                                        <Col lg="6" xs="12" >
                                            <SelectComponent
                                                label='Situação'
                                                options={situationList}
                                                onChange={(e) =>
                                                    setFilters({
                                                        ...filters,
                                                        selectedcgepsituacoesshape: e.target.value
                                                    })
                                                }
                                                value={filters.selectedcgepsituacoesshape}
                                            />
                                        </Col>
                                        <Col lg="6" xs="12" >
                                            <SelectComponent
                                                label='Tipo Ponto'
                                                options={tipoPontoList}
                                                onChange={(e) =>
                                                    setFilters({
                                                        ...filters,
                                                        selectedsgeptipoponto: e.target.value
                                                    })
                                                }
                                                value={filters.selectedsgeptipoponto}
                                            />
                                        </Col>
                                        <Col lg="6" xs="12" >
                                            <SelectComponent
                                                label='Origem'
                                                options={origemList}
                                                onChange={(e) =>
                                                    setFilters({
                                                        ...filters,
                                                        origem: e.target.value
                                                    })
                                                }
                                                value={filters.origem}
                                            />
                                        </Col>
                                    </Row>

                                    <FiltroButtonsComponent
                                        clearAllLayers={clearAllLayers}
                                        clearMapFilters={clearMapFilters}
                                        getPoints={getPoints}
                                        saveFilters={saveFilters}
                                        setGeoJson={setGeoJson}
                                    />
                                </>
                            }


                            {tipoPonto != 'C' && tipoPonto != 'D' &&
                                <FiltroCategoriaComponent
                                    labelName='Endereço'
                                    openedFilterName='endereco'
                                    clearMapFilters={clearMapFilters}
                                    setOpenedFilter={setOpenedFilter}
                                    openedFilter={openedFilter}
                                />}
                            {openedFilter === "endereco" &&
                                <>
                                    <FilterCheckboxLabelsComponent
                                        filters={filters}
                                        filledButton
                                        filled={filled}
                                        setFilled={(filled: any) => setFilled(filled)}
                                        labelArray={{
                                            valueName: ['logradouro', 'numero', 'sgepbairros', 'cep', 'sgepdistrito', 'referencia', 'descricao', 'ladologradouro', 'complemento', 'ccidade', 'tipo', 'sgepzoneamento', 'zona', 'principal'],
                                            labelName: ['Logradouro', 'Número', 'Bairro', 'CEP', 'Dístrito', 'Referência', 'Descrição', 'Lado Logradouro', 'Complemento', 'Cidade', 'Tipo', 'Zoneamento', 'Zona', 'Principal'],
                                            value: [filters.logradouro, filters.numero, filters.sgepbairros, filters.cep, filters.sgepdistrito, filters.referencia, filters.descricao, filters.ladologradouro, filters.complemento, filters.ccidade, filters.tipo, filters.sgepzoneamento, filters.zona, filters.principal]
                                        }}
                                        setState={setFilters} />

                                    <FiltroButtonsComponent
                                        clearAllLayers={clearAllLayers}
                                        clearMapFilters={clearMapFilters}
                                        getPoints={getPoints}
                                        saveFilters={saveFilters}
                                        setGeoJson={setGeoJson}
                                    />
                                </>
                            }

                            <FiltroCategoriaComponent
                                labelName='Cadastro'
                                openedFilterName='cadastro'
                                clearMapFilters={clearMapFilters}
                                setOpenedFilter={setOpenedFilter}
                                openedFilter={openedFilter}

                            />
                            {openedFilter === "cadastro" &&
                                <>
                                    {selectedPointType(tipoPonto)}
                                    <Row className='filtro-itens'>
                                        <Col lg="6" xs="12" >
                                            <Form.Label>Número de Histórico</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={filters.numeroHistoricos}
                                                onChange={(e: any) =>
                                                    setFilters({
                                                        ...filters,
                                                        numeroHistoricos: e.target.value
                                                    })}
                                                min={0}
                                                placeholder="Digite..." />
                                        </Col>
                                        <Col lg="6" xs="12" >
                                            <Form.Label>Número de arquivos digitais</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={filters.numeroArquivosDigitais}
                                                onChange={(e: any) => setFilters({ ...filters, numeroArquivosDigitais: e.target.value })}
                                                min={0}
                                                placeholder="Digite..." />
                                        </Col>
                                    </Row>
                                    <FiltroButtonsComponent
                                        clearAllLayers={clearAllLayers}
                                        clearMapFilters={clearMapFilters}
                                        getPoints={getPoints}
                                        saveFilters={saveFilters}
                                        setGeoJson={setGeoJson}
                                    />
                                </>}

                            {tipoPonto != 'D' &&
                                <FiltroCategoriaComponent
                                    labelName='Edificação'
                                    openedFilterName='edificacao'
                                    clearMapFilters={clearMapFilters}
                                    setOpenedFilter={setOpenedFilter}
                                    openedFilter={openedFilter}
                                />}
                            {openedFilter === "edificacao" &&
                                <>
                                    <FilterCheckboxLabelsComponent
                                        filters={filters}
                                        filledButton
                                        filled={filled}
                                        setFilled={(filled: any) => setFilled(filled)}
                                        labelArray={{
                                            valueName: ['possuiDescricao', 'possuiEdificacao', 'possuiArquivosDigitais'],
                                            labelName: ['Descrição', 'Edificações', 'Arquivos Digitais'],
                                            value: [filters.possuiDescricao, filters.possuiEdificacao, filters.possuiArquivosDigitais]
                                        }}
                                        filledText='Possui'
                                        setState={setFilters} />


                                    <Row className='filtro-itens'>
                                        <Col lg="6" xs="12" >
                                            <Form.Label>Número de edificações</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={filters.numeroEdificacao}
                                                onChange={(e: any) => setFilters({ ...filters, numeroEdificacao: e.target.value })}
                                                min={0}
                                                placeholder="Digite..." />
                                        </Col>
                                        <Col lg="6" xs="12" >
                                            <Form.Label>Número de arquivos digitais</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={filters.numeroArquivosDigitais}
                                                onChange={(e: any) => setFilters({ ...filters, numeroArquivosDigitais: e.target.value })}
                                                min={0}
                                                placeholder="Digite..." />
                                        </Col>
                                    </Row>
                                    <FiltroButtonsComponent
                                        clearAllLayers={clearAllLayers}
                                        clearMapFilters={clearMapFilters}
                                        getPoints={getPoints}
                                        saveFilters={saveFilters}
                                        setGeoJson={setGeoJson}
                                    />
                                </>}

                            {tipoPonto != 'D' &&
                                <FiltroCategoriaComponent
                                    labelName='Cadastro Técnico'
                                    openedFilterName='cadastro-tecnico'
                                    clearMapFilters={clearMapFilters}
                                    setOpenedFilter={setOpenedFilter}
                                    openedFilter={openedFilter}
                                />}
                            {openedFilter === "cadastro-tecnico" &&
                                <>
                                    <FilterCheckboxLabelsComponent
                                        filters={filters}
                                        filledButton
                                        filled={filled}
                                        setFilled={(filled: any) => setFilled(filled)}
                                        labelArray={{
                                            valueName: ['datainicial', 'datafinal', 'sgeptipoedificacao', 'inscricaomunicipal', 'descricao', 'observacao', 'possuiArquivosDigitais'],
                                            labelName: ['Data Inicial', 'Data Final', 'Tipo edificação', 'Inscrição Municipal', 'Descrição', 'Observação', 'Arquivos Digitas'],
                                            value: [filters.datainicial, filters.datafinal, filters.sgeptipoedificacao, filters.inscricaomunicipal, filters.descricao, filters.observacao, filters.possuiArquivosDigitais]
                                        }}
                                        setState={setFilters} />
                                    <Row className='filtro-itens'>
                                        <Col lg="6" xs="12" >
                                            <Form.Label>Número de arquivos digitais</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={filters.numeroArquivosDigitais}
                                                onChange={(e: any) => setFilters({ ...filters, numeroArquivosDigitais: e.target.value })}
                                                min={0}
                                                placeholder="Digite..." />
                                        </Col>
                                    </Row>
                                    <FiltroButtonsComponent
                                        clearAllLayers={clearAllLayers}
                                        clearMapFilters={clearMapFilters}
                                        getPoints={getPoints}
                                        saveFilters={saveFilters}
                                        setGeoJson={setGeoJson}
                                    />
                                </>}

                            {tipoPonto != 'D' &&
                                <FiltroCategoriaComponent
                                    labelName='Pessoas Físicas e Jurídicas'
                                    openedFilterName='pessoa'
                                    clearMapFilters={clearMapFilters}
                                    setOpenedFilter={setOpenedFilter}
                                    openedFilter={openedFilter}
                                />}
                            {openedFilter === "pessoa" &&
                                <>
                                    <Row className='filtro-itens text-center mx-2 mt-3' >
                                        <Col className='col-sm-6 col-lg-4'>
                                            <Form.Group className="filtro-form-group">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={filters.propriedade}
                                                    onChange={(e: any) => {
                                                        let propriedadeValue = !filters.propriedade
                                                        let edificacaoValue
                                                        if (propriedadeValue === true) {
                                                            edificacaoValue = false
                                                        } else {
                                                            edificacaoValue = true
                                                        }
                                                        setFilters(
                                                            {
                                                                ...filters,
                                                                propriedade: propriedadeValue,
                                                                edificacao: edificacaoValue
                                                            });
                                                    }}
                                                />
                                                <Form.Label className='filtro-label'>Propriedade</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-sm-6 col-lg-4'>
                                            <Form.Group className="filtro-form-group">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={filters.edificacao}
                                                    onChange={(e: any) => {
                                                        let edificacaoValue = !filters.edificacao
                                                        let propriedadeValue
                                                        if (edificacaoValue === true) {
                                                            propriedadeValue = false
                                                        } else {
                                                            propriedadeValue = true
                                                        }
                                                        setFilters(
                                                            {
                                                                ...filters,
                                                                propriedade: propriedadeValue,
                                                                edificacao: edificacaoValue
                                                            });
                                                    }}
                                                />
                                                <Form.Label className='filtro-label'>Edificação</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {(!!filters.edificacao || !!filters.propriedade) &&
                                        <>
                                            <FilterCheckboxLabelsComponent
                                                filters={filters}
                                                filledButton
                                                filled={filled}
                                                filledText='Possui'
                                                setFilled={(filled: any) => setFilled(filled)}
                                                labelArray={{
                                                    valueName: ['possuiVinculados'],
                                                    labelName: ['Vinculados'],
                                                    value: [filters.possuiVinculados]
                                                }}
                                                setState={setFilters} />
                                            <Row className='filtro-itens'>
                                                <Col lg="6" xs="12" >
                                                    <Form.Label>Número de nomes vinculadas</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={filters.numeroVinculados}
                                                        onChange={(e: any) => setFilters({ ...filters, numeroVinculados: e.target.value })}
                                                        min={0}
                                                        placeholder="Digite..." />
                                                </Col>
                                            </Row>
                                        </>}
                                    {(!!filters.numeroVinculados || filters.possuiVinculados) &&
                                        <FiltroButtonsComponent
                                            clearAllLayers={clearAllLayers}
                                            clearMapFilters={clearMapFilters}
                                            getPoints={getPoints}
                                            saveFilters={saveFilters}
                                            setGeoJson={setGeoJson}
                                            isLast
                                        />
                                    }
                                </>}
                        </>
                    }
                    <LoaderComponente show={showLoading} hide={() => setShowLoading(showLoading)} />
                </div>
            </div>
        )
    }
    return (
        <div>
            <Row>
                <Col xs={12}>
                    <div className='filtros'
                        id="relatorio">
                        <Image
                            title={"Relatorios"}
                            style={{ margin: 7, padding: 1, cursor: "pointer", alignItems: 'center' }}
                            src={filter}
                            width={16}
                            onClick={() => clickOpenFilters()}
                        />
                    </div>
                </Col>
            </Row>
            {isOpenFilter && gerarModalFilter()}
        </div >
    )
}