import React from 'react'
import { LocationInitialViewModel } from './LocationInitialViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { Marker} from 'leaflet';
import Select from 'react-select';

import "../../Estilo/Form.css";
import fechar from '../../Imagens/close-24.png'; 
import salvar from '../../Imagens/save-16.png'; 
import { MapaComponente } from '../../Components/MapaComponente';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";

export interface LocationInitialViewControllerDelegate {
    tapDimiss(navigation: any): void
}

interface MyProps {
    navigation: any
    delegate: LocationInitialViewControllerDelegate
    viewModel: LocationInitialViewModel
}

export class LocationInitialViewController extends React.Component<MyProps> {

    private formEvent: FormEvent
    private marker:any
    state = {
        isLoading: false,
        srid:  Array<any>(),
        json: {
            latitude: -30.1087957,
            longitude: -51.3172251
        }
    }

	constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }
    
    componentDidMount() {
        this.loadStruct()
        this.loadSRID()
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation}/>
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Localização Inicial</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                    onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                    src={fechar}/>
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Sistema de referência de coordenadas</Form.Label>
                                    <Select
                                    id="srid"
                                    value={this.formEvent.onValueSelect('select', 'srid', this.state.srid)}
                                    onChange={(e: any) => this.formEvent.onChange("select", e, "srid")}
                                    closeMenuOnSelect={true}
                                    placeholder={"Selecione"}
                                    options={this.state.srid}
                                    isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Latitude</Form.Label>
                                    <Form.Control
                                    id="latitude"
                                    disabled={true}
                                    value={json['latitude'] ? json['latitude'] : ''}
                                    defaultValue={json['latitude'] ? json['latitude'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Longitude</Form.Label>
                                    <Form.Control
                                    id="longitude"
                                    disabled={true}
                                    value={json['longitude'] ? json['longitude'] : ''}
                                    defaultValue={json['longitude'] ? json['longitude'] : ''}
                                    onChange={(e: any) => this.formEvent.onChange("double", e.target)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginBottom: 10}}>
                                    <MapaComponente
                                        height={600} 
                                        isDraw={false} 
                                        isCamada={false} 
                                        isSearch={false}
                                        isFilter={false}
                                        isNeighborRelatory={false}
                                        setActivedNeighborRelatory={() => console.log("Não implementado")}
                                        getClickShape={() => console.log('Não implementado')}
                                        getMap={(map: any) => this.setMap(map)}
                                        startEdit={() => console.log('Não implementado')}
                                        saveEdit={() => console.log('Não implementado')}
                                        cancelEdit={() => console.log('Não implementado')}
                                        stopDraw={() => console.log('Não implementado')}
                                        exportPDF={() => console.log("Não implementado")} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{marginTop: 20}}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                        src={salvar}/>
                                        Salvar
                                    </Button>
                                    {/* <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button> */}
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({isLoading: this.state.isLoading})}/>
            </div>
        )
    }

    private setMap(map: any){

        if(!map){
            return;
        }

        if(this.marker){
            map.removeLayer(this.marker);
        }
        
        let json = this.state.json as any
        this.marker = new Marker({lat: parseFloat(json['latitude']), lng: parseFloat(json['longitude'])}, {
            draggable: true
        }) as Marker;

        this.marker.on('dragend', (e: any) => {
            var latlng = e.target._latlng;
            json['latitude'] = latlng.lat
            json['longitude'] = latlng.lng

            this.setState({
                json: json
            })
        });

        map.addLayer(this.marker);
    }

    private save() {
        var json = this.state.json as any
        if(json['srid'] == undefined) {
            toast.error("Campos obrigatórios não preenchidos!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private loadStruct() {
        this.setState({isLoading: true})
        this.props.viewModel.fetchDataOne().then((rs) => {
            var resultado = rs as Array<any>
            if(resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })

                return
            }

            this.setState({isLoading: false})
        },e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }

    private loadSRID() {
        this.props.viewModel.fetchSelectSRID().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for(var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({value: item.srid, label: `EPSG ${item.srid}`, check: false})
            }

            this.setState({
                srid: array
            })
        });
    }
}