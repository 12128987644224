import { ExportDataRelatoryInteractor } from "../../Coordinator/ExportDataRelatory/ExportDataRelatoryInteractor";


export class ExportDataRelatoryViewModel {

    private interactor: ExportDataRelatoryInteractor
    constructor() {
        this.interactor = new ExportDataRelatoryInteractor()
    }

    public fetchExportDataRelatory(filters: any): PromiseLike<any> {
        return new Promise((res, rej) => {
            this.interactor.fetchExportDataRelatory(filters).then((result) => {
                return res(result)
            }, e => {
                return rej(e)
            })
        })
    }
}