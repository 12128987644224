import { Request } from '../../Servidor/Request';

export class ExportDataRelatoryInteractor {

    public fetchExportDataRelatory(filters: Array<any>): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPostDownload("relatorio/pontos/gerar-relatorio", { ...filters }, 'blob').then((response) => {
                const { data, fileName } = response;
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                res('Download iniciado');
            }, rej);
        });
    }
}