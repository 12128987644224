import React from 'react';
import { Row, Col, Image, Form } from 'react-bootstrap';
import { Request } from '../Servidor/Request';

import pastaAbertaAmarela from "../Imagens/pasta-aberta-amarela.jpg";
import pastaFechadaAmarela from "../Imagens/pasta-fechada-amarela.jpg";
import arquivoBranco from "../Imagens/arquivo-branco.png";
import layer from '../Imagens/layer.png';
import "../Estilo/Mapa.css";
import { LoaderComponente } from './LoaderComponente';

interface MyProps {
    isEdit: boolean,
    openCamada: (isOpen: Boolean) => void,
    setGeoJson: (scamada: Array<any>) => void,
    removeGeoJSON: (scamada: number) => void,
    getMap: () => any
    openFilter: (isOpen: Boolean) => void,
    isOpenCamada: Boolean,
    isOpenFilter: Boolean,
    selectedLayers: Array<{ value: string | number, label: string }>
    setSelectedLayers: (selectedLayers: Array<{ value: string | number, label: string }>) => void
}

export class CamadaComponente extends React.Component<MyProps> {

    state = {
        camadasHierarquia: new Array<any>(),
        showLoading: false,
        checkPasta: {},
        checkCamada: {}
    }

    componentDidMount() {
        this.buscarHierarquiaCamada();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <div id="camadas" className="camada" onClick={() => this.clickOpenCamada()}>
                            <Image
                                title={"Camadas"}
                                style={{ marginLeft: 3, cursor: "pointer", marginTop: 4 }}
                                src={layer}
                                width={24} />
                        </div>
                    </Col>
                </Row>
                {this.props.isOpenCamada &&
                    this.gerarMenuCamada()}
                <LoaderComponente show={this.state.showLoading} hide={() => this.setState({ showLoading: this.state.showLoading })} />
            </div>
        )
    }

    private clickOpenCamada() {
        var style = document.querySelector('.leaflet-left .leaflet-control')?.getAttribute('style');
        if (this.props.isOpenCamada) {
            document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', 'margin-left: 10px; margin-top: 20px;');
            document.querySelector('.procurar')?.setAttribute('style', `margin-top: 0px; margin-left: 0px;`)
            document.querySelector('.container-procurar')?.setAttribute('style', `margin-top: 0px; margin-left: 0px;`)
            document.querySelector('#camadas')?.setAttribute('style', `left: 10px;`)
            document.querySelector('.filtros')?.setAttribute('style', `left: -5px;`)
            document.querySelector('.neighbor-relatory')?.setAttribute('style', `left: 10px`)
            document.querySelector('.leaflet-control-measure')?.removeAttribute('style')
            document.querySelector('.coordinates')?.removeAttribute('style')
            document.querySelector('.leaflet-control-graphicscale-inner')?.removeAttribute('style')
            this.props.openCamada(false)
            return;
        }

        document.querySelector('.leaflet-left .leaflet-control')?.setAttribute('style', `${style}margin-left: 315px;`)
        document.querySelector('.procurar')?.setAttribute('style', `margin-top: 0px; margin-left: 305px;`)
        document.querySelector('.container-procurar')?.setAttribute('style', `margin-top: 0px; margin-left: 305px;`)
        document.querySelector('#camadas')?.setAttribute('style', `margin-left: 305px;`)
        document.querySelector('.filtros')?.setAttribute('style', `margin-left: 305px;`)
        document.querySelector('.neighbor-relatory')?.setAttribute('style', `margin-left: 305px;`)
        document.querySelector('.leaflet-control-measure')?.setAttribute('style', `margin-left: 315px;`)
        document.querySelector('.coordinates')?.setAttribute('style', 'margin-left: 290px;')
        document.querySelector('.leaflet-control-graphicscale-inner')?.setAttribute('style', 'margin-left: 305px;')
        this.props.openCamada(true)
        this.props.openFilter(false)

        localStorage.removeItem('layerOptions')
        localStorage.removeItem('selectedLayer')
        localStorage.removeItem('filters')
    }

    private buscarHierarquiaCamada() {

        new Request().requestPost('camada/buscar-hierarquia', {}).then((rs) => {
            if (rs.Status && rs.Status == "Sucesso!") {
                this.getCamadaInStorage(rs.Resultado)
                this.setState({
                    camadasHierarquia: rs.Resultado
                })
            }
        });

    }

    private gerarMenuCamada() {

        var arrayCamadas = this.state.camadasHierarquia;
        var arrayHierarquia = new Array<any>();
        var checkPasta = this.state.checkPasta as any;

        arrayCamadas.forEach((valor, index) => {
            var itensSub = valor.camadaFilhos as Array<any>;
            var check = checkPasta[valor.scamada] != undefined;
            arrayHierarquia.push(
                <div>
                    <Row key={`item-menu-${index}`}>
                        <Col xs="12" style={{ marginTop: 20, marginLeft: 10 }}>
                            <Image
                                style={{ float: "left" }}
                                src={check ? pastaAbertaAmarela : pastaFechadaAmarela}
                                width={20}
                                className="center"
                                fluid />
                            <Form.Check
                                style={{ fontSize: 12, marginLeft: 10, marginTop: -5, float: "left" }}
                                type={"checkbox"}>
                                <Form.Check.Input
                                    onChange={() => this.setChecked(true, 0, valor)}
                                    checked={check}
                                    type={"checkbox"} />
                                <Form.Check.Label style={{ marginTop: 3 }}>{valor.camada}</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    {check && itensSub.length > 0 && this.gerarMenuCamadaFilhos(valor.scamada, itensSub, 25)}
                </div>
            )
        });

        var classHierarquia = 'camada-hierarquia'
        if (document.querySelector('.camada')?.id == undefined) {
            classHierarquia = 'camada-hierarquia-draw'
        }

        return (
            <div id="camada-hierarquia" className={classHierarquia} style={{ height: window.innerHeight - 80 }}>
                {arrayHierarquia}
            </div>
        )

    }

    private gerarMenuCamadaFilhos(idCamadaPai: any, arrayCamadas: Array<any>, marginLeft: number) {

        var arrayHierarquia = new Array<any>();
        marginLeft += 5;
        arrayCamadas.forEach((valor, index) => {
            var itensSub = valor.camadaFilhos as Array<any>;
            var tipoImg = itensSub.length == 0 ? 'arquivo' : 'pasta';
            var check = false;
            if (tipoImg == 'arquivo') {
                var checkCamada = this.state.checkCamada as any;
                check = checkCamada[idCamadaPai] != undefined ? checkCamada[idCamadaPai][valor.scamada] != undefined ? true : false : false;
            } else {
                var checkPasta = this.state.checkPasta as any;
                check = checkPasta[valor.scamada] != undefined;
            }

            if (tipoImg == 'arquivo') {
                arrayHierarquia.push(
                    <div>
                        <Row>
                            <Col xs="12" style={{ marginTop: 10, marginLeft: marginLeft }} key={`item-menu-${index}`}>
                                <Image
                                    style={{ marginTop: -3, float: "left" }}
                                    src={tipoImg == 'arquivo' ? arquivoBranco : check ? pastaAbertaAmarela : pastaFechadaAmarela}
                                    width={20}
                                    className="center"
                                    fluid />
                                <Form.Check
                                    style={{ fontSize: 12, marginLeft: 10, marginTop: -5, float: "left" }}
                                    type={"checkbox"}>
                                    <Form.Check.Input
                                        onChange={() => this.setChecked(tipoImg == 'pasta', idCamadaPai, valor)}
                                        checked={check}
                                        type={"checkbox"} />
                                    <Form.Check.Label style={{ marginTop: 3, maxWidth: 200 }}>{valor.camada}</Form.Check.Label>
                                </Form.Check>
                            </Col>
                        </Row>
                        {check && itensSub.length > 0 && this.gerarMenuCamadaFilhos(valor.scamada, itensSub, marginLeft + 15)}
                    </div>
                )
            } else {
                arrayHierarquia.push(
                    <div>
                        <Row>
                            <Col xs="12" style={{ marginTop: 10, marginLeft: marginLeft }} key={`item-menu-${index}`}>
                                <Image
                                    style={{ marginTop: -3, float: "left" }}
                                    src={tipoImg == 'arquivo' ? arquivoBranco : check ? pastaAbertaAmarela : pastaFechadaAmarela}
                                    width={20}
                                    className="center"
                                    fluid />
                                <Form.Check
                                    style={{ fontSize: 12, marginLeft: 10, marginTop: -5, float: "left" }}
                                    type={"checkbox"}>
                                    <Form.Check.Input
                                        onChange={() => this.setChecked(tipoImg == 'pasta', idCamadaPai, valor)}
                                        checked={check}
                                        type={"checkbox"} />
                                    <Form.Check.Label style={{ marginTop: 3 }}>{valor.camada}</Form.Check.Label>
                                </Form.Check>
                            </Col>
                        </Row>
                        {check && itensSub.length > 0 && this.gerarMenuCamadaFilhos(valor.scamada, itensSub, marginLeft + 15)}
                    </div>
                )
            }

        });

        return arrayHierarquia;

    }

    private getCamadaInStorage(listCamadas: Array<any>) {
        var checkPasta = {} as any;
        var checkCamada = {} as any;

        if (localStorage.getItem('layers') != null) {
            let obj = JSON.parse(localStorage.getItem('layers')!)
            let layers = obj['layers'] as Array<any>

            let callback = (camadas: Array<any>, camadaPai: any) => {
                camadas.forEach((item) => {
                    var camadasFilhos = item['camadaFilhos'] as Array<any>
                    if (layers.indexOf(String(item['scamada'])) != -1) {
                        var keys = Object.keys(camadaPai)
                        keys.forEach((key) => {
                            checkPasta[key] = true
                        })

                        checkCamada[item['scamadapai']] = {} as any
                        checkCamada[item['scamadapai']][item['scamada']] = true
                    } else if (camadasFilhos.length > 0) {
                        camadaPai[item['scamada']] = true
                        callback(camadasFilhos, camadaPai)
                    }
                });
            }

            listCamadas.forEach((item) => {
                var camadasFilhos = item['camadaFilhos'] as Array<any>
                var camadaPai = {} as any
                camadaPai[item['scamada']] = true
                if (camadasFilhos.length > 0) {
                    callback(camadasFilhos, camadaPai)
                }

            })

            this.setState({
                checkPasta: checkPasta,
                checkCamada: checkCamada
            })
        }
    }

    private setChecked(isPasta: boolean, idCamadaPai: any, obj: any) {

        if (isPasta) {
            var checked = this.state.checkPasta as any;
            var checkedCamada = this.state.checkCamada as any;
            if (checked[obj.scamada]) {
                delete checked[obj.scamada];
                delete checkedCamada[obj.scamada];
            } else {
                checked[obj.scamada] = true;
            }

            this.setState({
                checkPasta: checked,
                checkCamada: checkedCamada
            });
            return;
        }

        var checked = this.state.checkCamada as any;
        if (!checked[idCamadaPai]) {
            checked[idCamadaPai] = {} as any;
            checked[idCamadaPai][obj.scamada] = true;
            this.props.setGeoJson([obj.scamada]);
        } else {
            if (checked[idCamadaPai][obj.scamada]) {
                delete checked[idCamadaPai][obj.scamada];
                this.props.removeGeoJSON(obj.scamada)
            } else {
                checked[idCamadaPai][obj.scamada] = true;
                this.props.setGeoJson([obj.scamada]);
            }
        }
        let haveLayerInStorage = JSON.parse(String(localStorage.getItem('layerOptions')))
        let updatedSelectedLayers = haveLayerInStorage ? [...haveLayerInStorage] : [...this.props.selectedLayers];
        const index = updatedSelectedLayers.findIndex(item => item.value === obj.scamada);
        if (index !== -1) {
            updatedSelectedLayers.splice(index, 1);
            this.props.setSelectedLayers(updatedSelectedLayers);
        } else {
            updatedSelectedLayers.push({ value: obj.scamada, label: obj.camada });
            this.props.setSelectedLayers(updatedSelectedLayers);
        }
        localStorage.setItem('layerOptions', JSON.stringify(updatedSelectedLayers))

        this.setState({
            checkCamada: checked
        });

    }

}