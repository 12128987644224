import { Request } from '../../Servidor/Request';

export class GlobalInteractor {

    public fetchDataToSelectInput(tabela: string, colunaPk: string, columnValue?: string): PromiseLike<any> {
        return new Promise((res, rej) => {
            return new Request().requestPost("basico/listagem", { tabela, colunaPk, columnValue }).then((rs) => {
                if (rs == undefined) {
                    return rej({
                        Mensagem: "Erro ao conectar"
                    })
                }
                res(rs.Resultado)
            }, e => {
                rej(e);
            });
        });
    }
}