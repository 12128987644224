import React from 'react';
import { Button, Col, Container, Form, Image, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { LoaderComponente } from "../../Components/LoaderComponente";
import { NavBarComponente } from '../../Components/NavBarComponente';
import "../../Estilo/Form.css";
import baixo from '../../Imagens/arrow-down-blue-16.png';
import voltar from '../../Imagens/arrow-left-16.png';
import voltarInicio from '../../Imagens/arrow-left-first-16.png';
import proximo from '../../Imagens/arrow-right-16.png';
import proximoUltimo from '../../Imagens/arrow-right-last-16.png';
import cima from '../../Imagens/arrow-up-blue-16.png';
import clearFilter from '../../Imagens/clear-filter.png';
import fechar from '../../Imagens/close-24.png';
import filtrar from '../../Imagens/filter-16.png';
import printer from '../../Imagens/printer.png';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { TypePagination } from '../TableGeneric/TableGenericViewController';
import { ExportDataRelatoryViewModel } from './ExportDataRelatoryViewModel';
import { GlobalViewModel } from '../Global/GlobalViewModel';
import { InputModalSelectComponent } from '../../Components/InputModalSelectComponent';
import { toast, ToastContainer } from 'react-toastify';

interface MyProps {
    navigation: any
    delegate: ExportDataRelatory
    viewModel: ExportDataRelatoryViewModel
}

export interface ExportDataRelatory {
    tapDimiss(navigation: any): void
}
export interface Filters {
    inscricaomunicipal: string;
    numeromatricula: string;
    numerolote: string;
    numeroquadra: string;
    sgepdistrito: string;
    sgepzoneamento: string;
    logradouro: string;
    sgepbairros: string;
    numPropriedades: string;
    tpprop: string;
    sgeppessoa: string;
    cgepsituacoesshape: string;
    numerogleba: string;
    sgeplocalizacaorural: string;
    seccao: string;
    pagina: number;
    numHistorico: number;
}

export class ExportDataRelatoryViewController extends React.Component<MyProps> {

    private globalViewModel = new GlobalViewModel()
    private formEvent: FormEvent
    state = {
        isLoading: true,
        json: {},
        propTypeList: [
            { value: 'U', label: 'Imóveis Urbanos' },
            { value: 'R', label: 'Imóveis Rurais' },
            { value: 'C', label: 'Cemiterios' },
            { value: 'D', label: 'Pontos diversos' },
        ],
        relatoryTypeList: [
            { value: 'xlsx', label: 'Xlsx' },
            { value: 'csv', label: 'Csv' },
        ],
        layersList: [],
        filters: ({
            layer: [],
            propertyType: '',
            relatoryType: ''
        }),
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.list()
        this.setState({
            isLoading: false
        })
    }



    render() {
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation} />
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Relatório Camadas</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                        onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                        src={fechar} />
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="6" xs="12" style={{ marginBottom: "20px" }}>
                                    <Form.Label>Camada</Form.Label>
                                    <Select
                                        id="layer"
                                        value={this.state.filters.layer ? this.formEvent.onValueSelect('select', 'camada', this.state.layersList) : null}
                                        onChange={(e: any) => this.setState({
                                            filters: ({
                                                ...this.state.filters,
                                                layer: [e.value]
                                            })
                                        })}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.layersList}
                                        isClearable
                                    />
                                </Col>
                                <Col lg="4" xs="12" style={{ marginBottom: "20px" }}>
                                    <Form.Label>Tipo do Ponto</Form.Label>
                                    <Select
                                        id="propertyType"
                                        value={this.state.filters.propertyType ? this.formEvent.onValueSelect('select', 'property_type', this.state.propTypeList) : null}
                                        onChange={(e: any) => this.setState({
                                            filters: ({
                                                ...this.state.filters,
                                                propertyType: e.value
                                            })
                                        })}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.propTypeList}
                                        isClearable
                                    />
                                </Col>
                                <Col lg="2" xs="12" style={{ marginBottom: "20px" }}>
                                    <Form.Label className="isRequired">Tipo do Relatório</Form.Label>
                                    <Select
                                        id="relatoryType"
                                        value={this.state.filters.relatoryType ? this.formEvent.onValueSelect('select', 'relatory_type', this.state.relatoryTypeList) : null}
                                        onChange={(e: any) => this.setState({
                                            filters: ({
                                                ...this.state.filters,
                                                relatoryType: e.value
                                            })
                                        })}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.relatoryTypeList}
                                        isClearable
                                    />
                                </Col>
                                <Col xs="12" className="d-flex">
                                    <Button variant="danger" type="button" className="colorRed mr-2" onClick={() => this.printRelatory(this.state.filters)}>
                                        <Image
                                            className="iconButtonGeneric"
                                            src={printer} />
                                        Exportar
                                    </Button>
                                    <Button variant="info" type="button" className="ms-2" onClick={() => this.clearFilters()}>
                                        <Image
                                            className="iconBtnFilter"
                                            src={clearFilter} />
                                        Limpar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({ isLoading: this.state.isLoading })} />
            </div>
        )
    }
    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private printRelatory(filters: any) {
        if (!filters.relatoryType) {
            this.showFieldsEmpty("Tipo de relatório é obrigatório!")
            return
        }
        this.setState({
            isLoading: true
        })

        this.props.viewModel.fetchExportDataRelatory(filters).then((data) => {
            this.setState({
                isLoading: false
            })
        })
    }

    private list() {
        this.globalViewModel.fetchDataToSelectInput('camada', 'scamada', 'camada').then((response) =>
            this.setState({
                layersList: response
            })
        )
    }

    private async clearFilters() {
        this.setState({
            filters: {
                layer: '',
                propertyType: '',
                relatoryType: ''
            }
        });
    }

}