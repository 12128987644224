import { AcessTypePropertyCoordinator } from "./AcessTypeProperty/AcessTypePropertyCoordinator"
import { AddressCoordinator } from "./Address/AddressCoordinator"
import { AdminProcessCoordinator } from "./AdminProcess/AdminProcessCoordinator"
import { AdminProcessMovementCoordinator } from "./AdminProcessMovement/AdminProcessMovementCoordinator"
import { AdminProcessStatusCoordinator } from "./AdminProcessStatus/AdminProcessStatusCoordinator"
import { AdminProcessTypeCoordinator } from "./AdminProcessType/AdminProcessTypeCoordinator"
import { AgriculturalRegistrationCoordinator } from "./AgriculturalRegistration/AgriculturalRegistrationCoordinator"
import { BatchLimitationCoordinator } from "./BatchLimitation/BatchLimitationCoordinator"
import { BuildingPermitCoordinator } from "./BuildingPermit/BuildingPermitCoordinator"
import { BuildingTypeCoordinator } from "./BuildingType/BuildingTypeCoordinator"
import { CemeteryConfigCoordinator } from "./CemeteryConfig/CemeteryConfigCoordinator"
import { ConsolidatedReportCoordinator } from "./ConsolidatedReport/ConsolidatedReportCoordinator"
import { ContactCoordinator } from "./Contact/ContactCoordinator"
import { CourseGroupCoordinator } from "./CourseGroup/CourseGroupCoordinator"
import { CourtSituationCoordinator } from "./CourtSituation/CourtSituationCoordinator"
import { CustodyTypeCoordinator } from "./CustodyType/CustodyTypeCoordinator"
import { DistrictCoordinator } from "./District/DistrictCoordinator"
import { DocumentTypeCoordinator } from "./DocumentType/DocumentTypeCoordinator"
import { DocumentTypeLinkCoordinator } from "./DocumentTypeLink/DocumentTypeLinkCoordinator"
import { EconActivityCoordinator } from "./EconActivity/EconActivityCoordinator"
import { EconActivityLinkCoordinator } from "./EconActivityLink/EconActivityLinkCoordinator"
import { ExportDataRelatoryCoordinator } from "./ExportDataRelatory/ExportDataRelatoryCoordinator"
import { FarmClassificationTpProductCoordinator } from "./FarmClassificationTpProduct/FarmClassificationTpProductCoordinator"
import { FarmProductCoordinator } from "./FarmProduct/FarmProductCoordinator"
import { FarmProductTypeCoordinator } from "./FarmProductType/FarmProductTypeCoordinator"
import { GroupVisitReasonCoordinator } from "./GroupVisitReason/GroupVisitReasonCoordinator"
import { ImmobileSituationCoordinator } from "./ImmobileSituation/ImmobileSituationCoordinator"
import { ImobiliaryRegisterRelatoryCoordinator } from "./ImobiliaryRegisterRelatory/ImobiliaryRegisterRelatoryCoordinator"
import { ImportRealStateRegistrationCoordinator } from "./ImportRealStateRegistration/ImportRealStateRegistrationCoordinator"
import { KinshipDegreeCoordinator } from "./KinshipDegree/KinshipDegreeCoordinator"
import { LayerCoordinator } from "./Layer/LayerCoordinator"
import { LayerPermissionCoordinator } from "./LayerPermission/LayerPermissionCoordinator"
import { LitigationCoordinator } from "./Litigation/LitigationCoordinator"
import { LocationInitialCoordinator } from "./LocationInitial/LocationInitialCoordinator"
import { LoginCoordinator } from "./Login/LoginCoordinator"
import { LotOccupationCoordinator } from "./LotOccupation/LotOccupationCoordinator"
import { MapInitialCoordinator } from "./MapInitial/MapInitialCoordinator"
import { MarkerIconCoordinator } from "./MarkerIcon/MarkerIconCoordinator"
import { MarkerImageCoordinator } from "./MarkerImage/MarkerImageCoordinator"
import { NeighborhoodCoordinator } from "./Neighborhood/NeighborhoodCoordinator"
import { PavingCoordinator } from "./Paving/PavingCoordinator"
import { PedologyCoordinator } from "./Pedology/PedologyCoordinator"
import { PersonCoordinator } from "./Person/PersonCoordinator"
import { PointCoordinator } from "./Point/PointCoordinator"
import { PointTypeCoordinator } from "./PointType/PointTypeCoordinator"
import { PoolCoordinator } from "./Pool/PoolCoordinator"
import { ProfessionalCoordinator } from "./Professional/ProfessionalCoordinator"
import { PropertyCoordinator } from "./Property/PropertyCoordinator"
import { PropertyBuildingCoordinator } from "./PropertyBuilding/PropertyBuildingCoordinator"
import { PropertyConfigCoordinator } from "./PropertyConfig/PropertyConfigCoordinator"
import { PropertyFormObtainingCoordinator } from "./PropertyFormObtaining/PropertyFormObtainingCoordinator"
import { PropertySituationCoordinator } from "./PropertySituation/PropertySituationCoordinator"
import { PublicRideCoordinator } from "./PublicRide/PublicRideCoordinator"
import { RealEstateRegistrationConfigCoordinator } from "./RealEstateRegistrationConfig/RealEstateRegistrationConfigCoordinator"
import { RuralLocationCoordinator } from "./RuralLocation/RuralLocationCoordinator"
import { RuralPropertySituationCoordinator } from "./RuralPropertySituation/RuralPropertySituationCoordinator"
import { SchoolCoordinator } from "./School/SchoolCoordinator"
import { SpecialNeedsCoordinator } from "./SpecialNeeds/SpecialNeedsCoordinator"
import { StudentCoordinator } from "./Student/StudentCoordinator"
import { TopographyCoordinator } from "./Topography/TopographyCoordinator"
import { TypeHeritageCoordinator } from "./TypeHeritage/TypeHeritageCoordinator"
import { TypeOfGraveCoordinator } from "./TypeOfGrave/TypeOfGraveCoordinator"
import { UnitProductionCoordinator } from "./UnitProduction/UnitProductionCoordinator"
import { UploadFilesCoordinator } from "./UploadFiles/UploadFilesCoordinator"
import { UserCoordinator } from "./User/UserCoordinator"
import { UserGroupCoordinator } from "./UserGroup/UserGroupCoordinator"
import { VisitCoordinator } from "./Visit/VisitCoordinator"
import { VisitReasonCoordinator } from "./VisitReason/VisitReasonCoordinator"
import { ZoningCoordinator } from "./Zoning/ZoningCoordinator"

export interface IndexCoordinatorDelegate {
    getRoute(): Array<JSX.Element>
}

export class IndexCoordinator {

    private routers: Array<JSX.Element>

    constructor() {
        this.routers = Array<JSX.Element>()
        this.initalRouters(new LoginCoordinator())
        this.initalRouters(new MapInitialCoordinator())
        this.initalRouters(new LayerCoordinator())
        this.initalRouters(new MarkerIconCoordinator())
        this.initalRouters(new MarkerImageCoordinator())
        this.initalRouters(new PersonCoordinator())
        this.initalRouters(new LocationInitialCoordinator())
        this.initalRouters(new ImmobileSituationCoordinator())
        this.initalRouters(new PointCoordinator())
        this.initalRouters(new VisitReasonCoordinator())
        this.initalRouters(new ProfessionalCoordinator())
        this.initalRouters(new VisitCoordinator())
        this.initalRouters(new UserGroupCoordinator())
        this.initalRouters(new UserCoordinator())
        this.initalRouters(new LayerPermissionCoordinator())
        this.initalRouters(new RuralPropertySituationCoordinator())
        this.initalRouters(new AcessTypePropertyCoordinator())
        this.initalRouters(new PropertyCoordinator())
        this.initalRouters(new UploadFilesCoordinator())
        this.initalRouters(new KinshipDegreeCoordinator())
        this.initalRouters(new PropertyBuildingCoordinator())
        this.initalRouters(new RealEstateRegistrationConfigCoordinator())
        this.initalRouters(new BuildingPermitCoordinator())
        this.initalRouters(new DistrictCoordinator())
        this.initalRouters(new PropertyFormObtainingCoordinator())
        this.initalRouters(new BuildingTypeCoordinator())
        this.initalRouters(new TypeHeritageCoordinator())
        this.initalRouters(new ZoningCoordinator())
        this.initalRouters(new PropertySituationCoordinator())
        this.initalRouters(new CourtSituationCoordinator())
        this.initalRouters(new LotOccupationCoordinator())
        this.initalRouters(new PavingCoordinator())
        this.initalRouters(new TopographyCoordinator())
        this.initalRouters(new PedologyCoordinator())
        this.initalRouters(new BatchLimitationCoordinator())
        this.initalRouters(new PublicRideCoordinator())
        this.initalRouters(new PoolCoordinator())
        this.initalRouters(new PropertyConfigCoordinator())
        this.initalRouters(new NeighborhoodCoordinator())
        this.initalRouters(new AddressCoordinator())
        this.initalRouters(new ContactCoordinator())
        this.initalRouters(new AdminProcessStatusCoordinator())
        this.initalRouters(new AdminProcessCoordinator())
        this.initalRouters(new AdminProcessTypeCoordinator())
        this.initalRouters(new LitigationCoordinator())
        this.initalRouters(new SchoolCoordinator())
        this.initalRouters(new CustodyTypeCoordinator())
        this.initalRouters(new ImportRealStateRegistrationCoordinator())
        this.initalRouters(new SpecialNeedsCoordinator())
        this.initalRouters(new CourseGroupCoordinator())
        this.initalRouters(new StudentCoordinator())
        this.initalRouters(new FarmProductTypeCoordinator())
        this.initalRouters(new FarmClassificationTpProductCoordinator())
        this.initalRouters(new FarmProductCoordinator())
        this.initalRouters(new DocumentTypeCoordinator())
        this.initalRouters(new DocumentTypeLinkCoordinator())
        this.initalRouters(new EconActivityCoordinator())
        this.initalRouters(new EconActivityLinkCoordinator())
        this.initalRouters(new AdminProcessMovementCoordinator())
        this.initalRouters(new PointTypeCoordinator())
        this.initalRouters(new RuralLocationCoordinator())
        this.initalRouters(new UnitProductionCoordinator())
        this.initalRouters(new TypeOfGraveCoordinator())
        this.initalRouters(new CemeteryConfigCoordinator())
        this.initalRouters(new ConsolidatedReportCoordinator())
        this.initalRouters(new ImobiliaryRegisterRelatoryCoordinator())
        this.initalRouters(new AgriculturalRegistrationCoordinator())
        this.initalRouters(new GroupVisitReasonCoordinator())
        this.initalRouters(new ExportDataRelatoryCoordinator())
    }

    private initalRouters(delegate: IndexCoordinatorDelegate) {
        var arrayRouters = delegate.getRoute()
        for (var i = 0; i < arrayRouters.length; i++) {
            this.routers.push(arrayRouters[i])
        }
    }

    public getRouter(): Array<JSX.Element> {
        return this.routers
    }

}